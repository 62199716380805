var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.LOGGED_IN ? _c('div', {
    staticClass: "fill-height",
    attrs: {
      "id": "incoming"
    }
  }, [_c('FileDropper', {
    ref: "FileDropper",
    attrs: {
      "endpoint": "file/upload/account/".concat(_vm.ACCOUNT.xid)
    },
    on: {
      "uploaded": _vm.loadInbox
    }
  }), _c('JobPicker', {
    ref: "JobPicker"
  }), _c('div', {
    staticClass: "fill-height",
    attrs: {
      "id": "scrollArea"
    },
    on: {
      "dragenter": _vm.dragEnter
    }
  }, [_c('v-container', [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "xl": "10"
    }
  }, [_c('v-row', _vm._l(_vm.files, function (file) {
    return _c('v-col', {
      key: file.xid,
      attrs: {
        "cols": "12",
        "md": "6",
        "lg": "4"
      }
    }, [_c('v-card', {
      staticClass: "pa-1"
    }, [_c('div', {
      staticClass: "d-flex"
    }, [_c('div', {
      staticClass: "flex-shrink-0 align-self-center py-1",
      staticStyle: {
        "width": "80px",
        "height": "90px"
      }
    }, [_c('FileIcon', {
      attrs: {
        "file": file
      }
    })], 1), _c('div', {
      staticClass: "text-truncate flex-grow-1"
    }, [_c('v-card-text', {
      staticClass: "py-1"
    }, [file.script ? _c('div', {
      staticClass: "text-caption"
    }, [_c('div', {
      staticClass: "d-flex"
    }, [_c('span', {
      staticClass: "font-weight-medium text-truncate"
    }, [_vm._v(_vm._s(_vm.types[file.script.type]))]), _c('v-spacer'), _c('span', {
      staticClass: "secondary--text"
    }, [_c('Moment', {
      attrs: {
        "timestamp": file.created_at,
        "mode": "calendar",
        "tooltip": "",
        "tooltip-type": "datetime"
      }
    })], 1)], 1), _c('div', {
      staticClass: "secondary--text text-truncate"
    }, [_vm._v(_vm._s(file.script.lasercam_job_number))]), _c('div', {
      staticClass: "secondary--text text-truncate"
    }, [_vm._v(_vm._s(file.script.clinicianName))]), _c('div', {
      staticClass: "secondary--text text-truncate"
    }, [_vm._v(_vm._s(file.script.clinicName))])]) : _c('div', {
      staticClass: "text-caption"
    }, [_c('div', {
      staticClass: "d-flex"
    }, [_c('span', {
      staticClass: "font-weight-medium text-truncate"
    }, [_vm._v(_vm._s(file.filename))]), _c('v-spacer'), _c('span', {
      staticClass: "secondary--text"
    }, [_c('Moment', {
      attrs: {
        "timestamp": file.created_at,
        "mode": "calendar",
        "tooltip": "",
        "tooltip-type": "datetime"
      }
    })], 1)], 1), _c('div', {
      staticClass: "secondary--text text-truncate"
    }, [_vm._v(_vm._s(_vm.string_bytes(file.size_bytes)))]), _c('div', {
      staticClass: "secondary--text text-truncate"
    }, [_vm._v(_vm._s(_vm.filetype(file)))]), _c('div', {
      staticClass: "secondary--text text-truncate"
    })])])], 1)]), _c('v-card-actions', {}, [file.script ? [file.script.hasPDF ? _c('v-btn', {
      attrs: {
        "x-small": "",
        "fab": "",
        "outlinedx": "",
        "elevation": "2",
        "color": "primary"
      },
      on: {
        "click": function click($event) {
          return _vm.openScriptPdf(file);
        }
      }
    }, [_c('v-icon', [_vm._v("mdi-file-document")])], 1) : _vm._e(), file.script.hasScans ? _c('v-btn', {
      attrs: {
        "x-small": "",
        "fab": "",
        "outlinedx": "",
        "elevation": "2",
        "color": "primary"
      },
      on: {
        "click": function click($event) {
          return _vm.openScansInPrescribe(file);
        }
      }
    }, [_c('v-icon', [_vm._v("mdi-rotate-3d")])], 1) : _vm._e(), _c('v-menu', {
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref) {
          var on = _ref.on,
            attrs = _ref.attrs;
          return [_c('v-btn', _vm._g(_vm._b({
            staticClass: "mx-2",
            attrs: {
              "x-small": "",
              "fab": "",
              "outlined": "",
              "xelevation": "2",
              "color": "primary"
            }
          }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-dots-horizontal")])], 1)];
        }
      }], null, true)
    }, [_c('v-list', [_c('v-list-item', {
      attrs: {
        "link": "",
        "small": ""
      },
      on: {
        "click": function click($event) {
          return _vm.downloadFile(file);
        }
      }
    }, [_c('v-icon', {
      staticClass: "mr-4 primary--text"
    }, [_vm._v("mdi-download")]), _vm._v("Download")], 1), _c('v-list-item', {
      attrs: {
        "link": "",
        "small": ""
      },
      on: {
        "click": function click($event) {
          return _vm.deleteFile(file);
        }
      }
    }, [_c('v-icon', {
      staticClass: "mr-4 error--text"
    }, [_vm._v("mdi-trash-can")]), _vm._v("Delete")], 1), _c('v-list-item', {
      attrs: {
        "link": "",
        "small": ""
      },
      on: {
        "click": function click($event) {
          return _vm.matchingJobs(file);
        }
      }
    }, [_c('v-icon', {
      staticClass: "mr-4 primary--text"
    }, [_vm._v("mdi-magnify")]), _vm._v("Matching Jobs")], 1)], 1)], 1), _c('v-spacer'), _c('v-btn', {
      staticClass: "pa-4",
      attrs: {
        "small": "",
        "outlinedx": "",
        "color": "primary"
      },
      on: {
        "click": function click($event) {
          return _vm.moveToJob(file);
        }
      }
    }, [_c('v-icon', {
      staticClass: "mr-2",
      attrs: {
        "small": ""
      }
    }, [_vm._v("mdi-briefcase-variant")]), _vm._v("Add to Job")], 1)] : [_c('v-btn', {
      attrs: {
        "x-small": "",
        "fab": "",
        "outlinedx": "",
        "elevation": "2",
        "color": "primary"
      },
      on: {
        "click": function click($event) {
          return _vm.downloadFile(file);
        }
      }
    }, [_c('v-icon', [_vm._v("mdi-download")])], 1), _c('v-menu', {
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref2) {
          var on = _ref2.on,
            attrs = _ref2.attrs;
          return [_c('v-btn', _vm._g(_vm._b({
            staticClass: "mx-2",
            attrs: {
              "x-small": "",
              "fab": "",
              "outlined": "",
              "xelevation": "2",
              "color": "primary"
            }
          }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-dots-horizontal")])], 1)];
        }
      }], null, true)
    }, [_c('v-list', [_c('v-list-item', {
      attrs: {
        "link": "",
        "small": ""
      },
      on: {
        "click": function click($event) {
          return _vm.deleteFile(file);
        }
      }
    }, [_c('v-icon', {
      staticClass: "mr-4 error--text"
    }, [_vm._v("mdi-trash-can")]), _vm._v("Delete")], 1)], 1)], 1), _c('v-spacer'), _c('v-btn', {
      staticClass: "pa-4",
      attrs: {
        "small": "",
        "outlinedx": "",
        "color": "primary"
      },
      on: {
        "click": function click($event) {
          return _vm.moveToJob(file);
        }
      }
    }, [_c('v-icon', {
      staticClass: "mr-2",
      attrs: {
        "small": ""
      }
    }, [_vm._v("mdi-briefcase-variant")]), _vm._v("Add to Job")], 1)]], 2)], 1)], 1);
  }), 1)], 1)], 1)], 1)], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.LOGGED_IN ? _c('v-container', {
    attrs: {
      "id": "page"
    }
  }, [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "xl": "10"
    }
  }, [_c('Education', [_c('ul', [_c('li', [_vm._v("List each of your customers business names here.")]), _c('li', [_vm._v("Click on the customer to add their staff and business locations.")])])]), _c('v-sheet', {
    staticClass: "white rounded",
    staticStyle: {
      "overflow": "hidden"
    },
    attrs: {
      "rounded": "",
      "elevation": "2"
    }
  }, [_c('v-data-table', {
    staticClass: "transparent",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.customers,
      "search": _vm.filter,
      "sort-by": ['name'],
      "options": _vm.options,
      "dense": "",
      "footer-props": {
        'items-per-page-options': [10, 20, 30, 40, 50],
        'show-first-last-page': true,
        'show-current-page': true
      }
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.options = $event;
      },
      "click:row": _vm.clickCustomerRow
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function fn() {
        return [_c('v-sheet', {
          staticClass: "primary px-3 pt-3"
        }, [_c('v-row', [_c('v-col', {
          attrs: {
            "rows": "12",
            "md": "6",
            "lg": "4",
            "xl": "3"
          }
        }, [_c('v-text-field', {
          attrs: {
            "dark": "",
            "dense": "",
            "rounded": "",
            "outlined": "",
            "hide-details": "",
            "placeholder": "search",
            "prepend-icon": "mdi-magnify"
          },
          model: {
            value: _vm.filter,
            callback: function callback($$v) {
              _vm.filter = $$v;
            },
            expression: "filter"
          }
        })], 1), _c('v-spacer'), _c('v-col', {
          staticClass: "text-right",
          attrs: {
            "rows": "12",
            "md": "6",
            "lg": "4",
            "xl": "3"
          }
        }, [_c('v-btn', {
          staticClass: "white--text",
          attrs: {
            "rounded": "",
            "outlined": ""
          },
          on: {
            "click": _vm.clickNewCustomer
          }
        }, [_c('v-icon', [_vm._v("mdi-plus")]), _vm._v("New")], 1)], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "item.edit",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('v-btn', {
          attrs: {
            "icon": ""
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.editCustomer_show(item.xid);
            }
          }
        }, [_c('v-icon', {
          staticClass: "primary--text"
        }, [_vm._v("mdi-pencil")])], 1)];
      }
    }], null, false, 3647205149)
  })], 1), _vm.editCustomer.show ? _c('v-dialog', {
    attrs: {
      "max-width": "450",
      "persistent": ""
    },
    model: {
      value: _vm.editCustomer.show,
      callback: function callback($$v) {
        _vm.$set(_vm.editCustomer, "show", $$v);
      },
      expression: "editCustomer.show"
    }
  }, [_c('v-card', {
    attrs: {
      "elevation": "20",
      "disabled": _vm.editCustomer.loading
    }
  }, [_c('v-card-title', {
    staticClass: "text-h6 primary white--text py-3"
  }, [_vm._v(" Update Customer ")]), _c('v-card-text', {
    staticClass: "pt-8 text-subtitle-2"
  }, [_c('v-row', {
    staticStyle: {}
  }, [_c('v-col', {
    staticStyle: {},
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rounded": "",
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "label": "Customer Name"
    },
    model: {
      value: _vm.editCustomer.name,
      callback: function callback($$v) {
        _vm.$set(_vm.editCustomer, "name", $$v);
      },
      expression: "editCustomer.name"
    }
  })], 1), _c('v-col', {
    staticStyle: {},
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rounded": "",
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "label": "Reference (4 Letters)"
    },
    model: {
      value: _vm.editCustomer.ref,
      callback: function callback($$v) {
        _vm.$set(_vm.editCustomer, "ref", $$v);
      },
      expression: "editCustomer.ref"
    }
  })], 1)], 1)], 1), _c('v-card-actions', {
    staticClass: "pb-4"
  }, [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "secondary darken-1"
    },
    on: {
      "click": function click($event) {
        _vm.editCustomer.show = false;
      }
    }
  }, [_vm._v("Cancel")]), _c('v-btn', {
    attrs: {
      "color": "primary darken-1"
    },
    on: {
      "click": _vm.editCustomer_save
    }
  }, [_vm._v("Update")])], 1)], 1)], 1) : _vm._e()], 1)], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-expand-transition>
  <div v-if="EDUCATION">
    <v-alert
          class='primary white--text'
          icon="mdi-school"
        >
          <slot />
        </v-alert>
  </div>
</v-expand-transition>
</template>

<style lang="scss">
//@import "@/assets/common";
</style>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Education',

  components: {
  },

  data: () => ({
  }),

  computed: {
    ...mapGetters('UI', ['EDUCATION']),
  },

  methods:{
  },

  mounted() {
  },

};
</script>

<template>
  <div id="container" class='' @dragenter.prevent='dragEnter' @dragleave='dragLeave' @drop.prevent='drop' @dragover.prevent ref='outer'>
    <div id="inner" :style='{...visibility}' ref='inner'>
      <div id='text' class='pa-2 text-h4 white--text font-weight-medium'>
        <p><v-icon class='mr-2' color='white'>mdi-upload</v-icon> Upload File</p>
        <p class='text-body-1 text-center' v-if='uploading'>{{uploading.name}} ({{progress}}%)</p>
        <p class='text-caption text-center' v-if='uploads.length'>{{uploads.length}} file{{uploads.length!=1?'s':''}} remaining.</p>
      </div>

    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/common";
#container{
  position: absolute; left: 0px; top: 0px;
  width: 100%; height: 100%;
  max-height: 100vh;
  display: grid; place-items: center;
  //background-color: #F00E;
  //pointer-events: none;
  
  #inner{
    width: 100%; height: 100%;
    display: grid; place-items: center;
    background-color: #000C;
    border: 5px dashed darken(white, 50%);
    #text{
      pointer-events: none;
    }
  }
}
</style>

<script>
import { mapActions } from 'vuex';

export default {
  name: '',

  components: {
  },

  props: {
    endpoint: { type: String, required: true },
  },

  data: () => ({
    hover: false,
    uploads: [],
    progress: 0,
    uploading: null,
  }),

  computed: {
    visibility(){
      let visible = this.hover || this.uploads.length || this.uploading;
      return {
      zIndex: visible ? 1 : 0,
      display: visible ? 'grid' : 'none',
    }},
  },

  watch: {
    uploading(){ this.uploadNext(); },
  },

  methods:{
    ...mapActions("API", ["API"]),
    dragEnter(e){
      console.log(e);
      //if(e.target != this.$refs.outer) return;
      this.hover = true;
      e.dataTransfer.dropEffect = 'link';
    },
    dragLeave(e){
      if(e.target != this.$refs.inner) return;
      this.hover = false;
    },
    drop(e){
      this.hover = false;
      e.preventDefault();
      [...e.dataTransfer.files].forEach(f => {
        this.uploads.push(f);
        this.uploadNext();
      });
    },
    uploadNext(){
      if(this.uploading) return; //skip if already running
      if(!this.uploads.length) return; //nothing to upload
      this.progress = 0;
      this.uploading = this.uploads.shift();
      let data = new FormData();
      data.append('file', this.uploading);
      this.API({ method: 'POST', endpoint: this.endpoint, data, progress: this.updateProgress })
      .then(() => { this.$emit('uploaded') })
      .catch(() => { this.ALERT("Failed to upload."); })
      .finally(() => { this.uploading = null; });
    },
    updateProgress(e){ this.progress = Math.round((e.loaded * 100) / e.total); },

  },

  mounted() {
  },

};
</script>

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "outer",
    attrs: {
      "id": "container"
    },
    on: {
      "dragenter": function dragenter($event) {
        $event.preventDefault();
        return _vm.dragEnter($event);
      },
      "dragleave": _vm.dragLeave,
      "drop": function drop($event) {
        $event.preventDefault();
        return _vm.drop($event);
      },
      "dragover": function dragover($event) {
        $event.preventDefault();
      }
    }
  }, [_c('div', {
    ref: "inner",
    style: Object.assign({}, _vm.visibility),
    attrs: {
      "id": "inner"
    }
  }, [_c('div', {
    staticClass: "pa-2 text-h4 white--text font-weight-medium",
    attrs: {
      "id": "text"
    }
  }, [_c('p', [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "color": "white"
    }
  }, [_vm._v("mdi-upload")]), _vm._v(" Upload File")], 1), _vm.uploading ? _c('p', {
    staticClass: "text-body-1 text-center"
  }, [_vm._v(_vm._s(_vm.uploading.name) + " (" + _vm._s(_vm.progress) + "%)")]) : _vm._e(), _vm.uploads.length ? _c('p', {
    staticClass: "text-caption text-center"
  }, [_vm._v(_vm._s(_vm.uploads.length) + " file" + _vm._s(_vm.uploads.length != 1 ? 's' : '') + " remaining.")]) : _vm._e()])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }
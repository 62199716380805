var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.valid ? _c('span', [_c('v-tooltip', {
    attrs: {
      "bottom": "",
      "disabled": !_vm.tooltip
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('span', _vm._g(_vm._b({}, 'span', attrs, false), on), [_vm.mode == "date" ? _c('span', [_vm._v(_vm._s(_vm.date))]) : _vm._e(), _vm.mode == "timeUntil" ? _c('span', [_vm._v(_vm._s(_vm.timeUntil))]) : _vm._e(), _vm.mode == "timeAgo" ? _c('span', [_vm._v(_vm._s(_vm.timeAgo))]) : _vm._e(), _vm.mode == "calendar" ? _c('span', [_vm._v(_vm._s(_vm.calendar))]) : _vm._e(), _vm.mode == "datetime" ? _c('span', [_vm._v(_vm._s(_vm.datetime))]) : _vm._e()])];
      }
    }], null, false, 710071029)
  }, [_c('span', [_vm._v(_vm._s(_vm.tooltipText))])])], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <span v-if='valid'>
    <v-tooltip bottom :disabled="!tooltip">
      <template v-slot:activator="{ on, attrs }">
        <span v-on="on" v-bind="attrs">
          <span v-if='mode == "date"'>{{date}}</span>
          <span v-if='mode == "timeUntil"'>{{timeUntil}}</span>
          <span v-if='mode == "timeAgo"'>{{timeAgo}}</span>
          <span v-if='mode == "calendar"'>{{calendar}}</span>
          <span v-if='mode == "datetime"'>{{datetime}}</span>
        </span>
      </template>
      <span>{{tooltipText}}</span>
    </v-tooltip>
  </span>

</template>

<style lang="scss">
//@import "@/assets/common";
</style>

<script>
import Moment from 'moment';

export default {
  name: 'Moment',

  components: {
  },

  props: {
    'timestamp': { type: String, required: false, default: '' },
    'mode': { type: String, required: false, default: 'date' },
    'tooltip': { type: Boolean, required: false, default: false },
    'tooltipType': { type: String, required: false, default: 'date' }
  },

  watch:{
    timestamp(){
      this.updateTimestamp();
    },
  },

  data: () => ({
    moment: null,
    ready: false,
    format: "DD/MM/YY",
  }),

  computed: {
    valid(){
      return this.moment?.isValid() ? true : false;
    },
    date(){
      return this.moment.format(this.format);
    },
    timeUntil(){
      return this.moment.from(Moment());
    },
    timeAgo(){
      return Moment().to(this.moment);
    },
    calendar(){
      return this.moment.calendar(Moment(), {
        sameDay: 'h:mm A',
        nextDay: '[Tomorrow]',
        nextWeek: this.format,
        lastDay: '[Yesterday]',
        lastWeek: this.format,
        sameElse: this.format
      });
    },
    datetime(){
      return this.moment.format("DD/MM/YY h:mm A");
    },
    tooltipText(){
      if(this.tooltipType == 'date') return this.date;
      if(this.tooltipType == 'timeUntil') return this.timeUntil;
      if(this.tooltipType == 'timeAgo') return this.timeAgo;
      if(this.tooltipType == 'calendar') return this.calendar;
      if(this.tooltipType == 'datetime') return this.datetime;
      return this.date;
    }
  },

  methods:{
    updateTimestamp(){
      this.moment = Moment(this.timestamp);
    }
  },

  created() {
    this.updateTimestamp();
  },

};
</script>

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {}, [_vm.file.script ? _c('v-img', {
    attrs: {
      "src": "/File-Icons/".concat(_vm.file.script.type, ".svg"),
      "height": _vm.height,
      "width": _vm.width,
      "contain": ""
    }
  }) : _c('v-img', {
    attrs: {
      "src": "/File-Icons/".concat(_vm.icon(_vm.file), ".svg"),
      "height": _vm.height,
      "width": _vm.width,
      "contain": ""
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "900",
      "persistent": "",
      "scrollable": ""
    },
    model: {
      value: _vm.active,
      callback: function callback($$v) {
        _vm.active = $$v;
      },
      expression: "active"
    }
  }, [_c('v-card', {
    attrs: {
      "elevation": "20",
      "disabled": _vm.loading
    }
  }, [_c('v-card-title', {
    staticClass: "primary white--text"
  }, [_vm._v("Select Job")]), _c('v-card-text', {
    staticClass: "text-subtitle-2 pt-4"
  }, [_vm.lasercam_job_number ? _c('p', {
    staticClass: "text-center caption"
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-information")]), _vm._v("Found " + _vm._s(_vm.jobs.length) + " job" + _vm._s(_vm.jobs.length == 1 ? '' : 's') + " which contain this script. ")], 1) : _c('v-text-field', {
    attrs: {
      "label": "Find Job",
      "prepend-icon": "mdi-magnify",
      "autofocus": ""
    },
    model: {
      value: _vm.search,
      callback: function callback($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  }), _c('v-data-table', {
    attrs: {
      "items": _vm.jobs,
      "item-key": "xid",
      "headers": _vm.headers,
      "search": _vm.search,
      "checkbox-color": "blue",
      "dense": "",
      "show-select": "",
      "single-select": ""
    },
    on: {
      "click:row": _vm.clickJob
    },
    model: {
      value: _vm.jobsSelected,
      callback: function callback($$v) {
        _vm.jobsSelected = $$v;
      },
      expression: "jobsSelected"
    }
  })], 1), _c('v-card-actions', {
    staticClass: "pt-0"
  }, [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.clickSelect
    }
  }, [_vm._v(_vm._s(_vm.jobSelected ? "Add to Job" : "New Job"))]), _c('v-btn', {
    attrs: {
      "color": "secondary"
    },
    on: {
      "click": _vm.clickCancel
    }
  }, [_vm._v("Cancel")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <span class=''>
    <v-img v-if='file.script' :src='`/File-Icons/${file.script.type}.svg`' :height='height' :width='width' contain />
    <v-img v-else :src='`/File-Icons/${icon(file)}.svg`' :height='height' :width='width' contain />
  </span>
</template>

<style lang="scss">
//@import "@/assets/common";
</style>

<script>

export default {
  name: '',

  components: {
  },

  props: {
    file: { type: Object, required: true },
    width: { type: Number, required: false, default: null },
    height: { type: Number, required: false, default: null },

  },

  data: () => ({
  }),

  computed: {
  },

  methods:{
    icon(file){
      let ft = file.filename.split('.').pop().toUpperCase();
      let icons = ['ZIP', 'PDF']
      return icons.includes(ft) ? ft : 'FILE';
    },
  },

  mounted() {
  },

};
</script>

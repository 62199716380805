<template>
  <v-container v-if='LOGGED_IN' id='page'><v-row justify='center'><v-col cols=12 xl=10>

    <Education>
      <ul>
        <li>List each of your customers business names here.</li>
        <li>Click on the customer to add their staff and business locations.</li>
      </ul>
    </Education>

    <v-sheet rounded elevation="2" class='white rounded' style='overflow:hidden;'>
      <v-data-table
        :headers='headers'
        :items='customers'
        :search='filter'
        :sort-by="['name']"
        :options.sync="options"
        class='transparent'
        @click:row='clickCustomerRow'
        dense
        :footer-props="{ 'items-per-page-options':[10,20,30,40,50], 'show-first-last-page': true, 'show-current-page': true }"
      >
      <template v-slot:top>
        <v-sheet class='primary px-3 pt-3'>
          <v-row>
            <v-col rows=12 md=6 lg=4 xl=3>
              <v-text-field v-model='filter' dark dense rounded outlined hide-details placeholder='search' prepend-icon="mdi-magnify" />
            </v-col>
            <v-spacer />
            <v-col rows=12 md=6 lg=4 xl=3 class='text-right'>
              <v-btn rounded class="white--text" outlined @click='clickNewCustomer'><v-icon>mdi-plus</v-icon>New</v-btn>
            </v-col>
          </v-row>
        </v-sheet>
      </template>
      <template v-slot:item.edit="{item}">
        <v-btn icon @click.stop='editCustomer_show(item.xid)'><v-icon class='primary--text'>mdi-pencil</v-icon></v-btn>
      </template>
    </v-data-table>
  </v-sheet>

  <v-dialog v-model="editCustomer.show" v-if='editCustomer.show' max-width="450" persistent>
    <v-card elevation="20" :disabled='editCustomer.loading'>
      <v-card-title class="text-h6 primary white--text py-3">
        Update Customer
      </v-card-title>
      <v-card-text class='pt-8 text-subtitle-2'>
        <v-row style='py-0'>
          <v-col cols=12 style='py-0'><v-text-field v-model='editCustomer.name' rounded outlined dense hide-details label='Customer Name'/></v-col>
          <v-col cols=12 style='py-0'><v-text-field v-model='editCustomer.ref' rounded outlined dense hide-details label='Reference (4 Letters)'/></v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class='pb-4'>
        <v-spacer></v-spacer>
        <v-btn color="secondary darken-1" @click='editCustomer.show = false;'>Cancel</v-btn>
        <v-btn color="primary darken-1" @click='editCustomer_save'>Update</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  </v-col></v-row></v-container>
</template>

<style lang="scss" scoped>
@import "@/assets/common";



#page{
  .v-data-table::v-deep{
    position: relative;
    thead{
      background-color: $primary;
      th{
        color: white;
      }
    }
    tr{
      cursor: pointer;
    }
  }
}

</style>


<script>
import { mapActions, mapGetters } from 'vuex'
import Education from '@/components/Education'

export default {
  name: 'Customers',

  components: {
    Education
  },

  data: () => ({
    customers: [],
    filter: "",
    editCustomer: {
      show: false,
      loading: false,
      name: "test",
      xid: null,
      ref: null,
    },
    options: {
      page: 1, sortBy: [], sortDesc: [], itemsPerPage: 30
    },
  }),

  computed: {
    ...mapGetters('Auth', ['LOGGED_IN', 'TOKEN', 'MODULES']),

    headers(){
      return [
        //{ text: 'ID', value: 'id' },
        { text: 'Ref#', value: 'ref', width: '100px' },
        { text: 'Customer', value: 'name', align: 'start' },
        { text: 'Edit', value: 'edit', width: '100px', sortable: false, filterable: false, },
      ];
    },

    customersFiltered(){
      return this.customers;
    },

  },

  methods:{
    ...mapActions('UI', ['SHOW_NAVBARS', 'NOTIFY', 'SET_PAGE_TITLE', 'PROMPT', 'NAVIGATE']),
    ...mapActions('Auth', ['REQUIRE_LOGIN']),
    ...mapActions('API', ['API']),

    clickNewCustomer(){
      let newCustomerId = null;
      this.API({method: 'GET', endpoint: 'customers/create'})
      .then(id => { newCustomerId = id; })
      .then(this.loadCustomers)
      .then(()=>{ this.editCustomer_show(newCustomerId); });
    },

    loadCustomers(){
      return this.API({ method: 'GET', endpoint: 'customers' })
      .then(customers => {
        this.customers = customers;
      });
    },

    clickCustomerRow(customer){
      window.open(`/#/customer/${customer.xid}`, '_blank');
    },

    editCustomer_show(customerId){
      let customer = this.customers.find(c => c.xid == customerId);
      this.editCustomer.xid = customer.xid;
      this.editCustomer.ref = customer.ref;
      this.editCustomer.name = customer.name;
      this.editCustomer.show = true;
    },

    editCustomer_save(){
      this.editCustomer.loading = true;
      this.API({method: 'PATCH', endpoint: `customers/${this.editCustomer.xid}`, data: {
        name: this.editCustomer.name,
        ref: this.editCustomer.ref,
      }})
      .then(this.loadCustomers)
      .then(()=>{
        this.editCustomer.show = false;
      })
      .finally(()=>{ this.editCustomer.loading = false; });
    }

  },

  mounted() {
    this.REQUIRE_LOGIN(()=>{
      this.SHOW_NAVBARS()
      this.SET_PAGE_TITLE('Customers');
      this.loadCustomers()
    });
  },

};
</script>

<template>
  <v-dialog v-model="active" max-width="900" persistent scrollable>
    <v-card elevation="20" :disabled="loading">
      <v-card-title class='primary white--text'>Select Job</v-card-title>
      <v-card-text class='text-subtitle-2 pt-4'>
        <p v-if='lasercam_job_number' class='text-center caption'>
          <v-icon class='mr-2' small>mdi-information</v-icon>Found {{jobs.length}} job{{jobs.length==1?'':'s'}} which contain this script.
        </p>
        <v-text-field v-else label='Find Job' v-model='search' prepend-icon='mdi-magnify' autofocus />

        <v-data-table :items='jobs' item-key='xid' :headers='headers' :search='search' v-model='jobsSelected'
          @click:row='clickJob'
          checkbox-color='blue'
          dense show-select single-select
        />
      </v-card-text>
      <v-card-actions class='pt-0'>
        <v-spacer />
        <v-btn color="primary" @click='clickSelect'>{{jobSelected?"Add to Job":"New Job"}}</v-btn>
        <v-btn color="secondary" @click='clickCancel'>Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss">
//@import "@/assets/common";
</style>

<script>
import { mapActions } from 'vuex'
import Deferred from '@/helpers/Deferred'
export default {
  name: 'JobPicker',

  components: {
  },

  data: () => ({
    active: false,
    loading: false,
    jobs: [],
    jobsSelected: [],
    jobSelected: null,
    search: '',
    lasercam_job_number: null,
    headers: [
      { text: 'Ref#', value: 'ref' },
      { text: 'Description', value: 'description' },
      { text: 'Status', value: 'status.name' },
    ],
    promise: null,
  }),

  computed: {
  },

  watch: {
    active(active){ if(active) this.refreshJobs(); },
    jobsSelected(jobs){ this.jobSelected = jobs[0]?.xid ?? null; },
    jobSelected(xid){ this.jobsSelected = xid ? [this.jobs.find(j => j.xid == xid)] : []; },
  },

  methods:{
    ...mapActions("API", ["API"]),

    refreshJobs(){
      this.loading = true;
      let endpoint = this.lasercam_job_number ? `jobs_matching_script/${this.lasercam_job_number}` : 'jobs';
      return this.API({ method: 'GET', endpoint })
      .then(jobs => { this.jobs = jobs })
      .finally(() => { this.loading = false; });
    },

    pick({lasercam_job_number = null}){
      this.lasercam_job_number = lasercam_job_number;
      this.jobSelected = null;
      this.jobs = [];
      this.active = true;
      this.promise = new Deferred();
      return this.promise.promise;
    },

    clickCancel(){
      this.active = false;
      this.promise.reject();
    },

    clickSelect(){
      this.active = false;
      this.promise.resolve(this.jobSelected);
    },

    clickJob(job){
      this.jobSelected = this.jobSelected != job.xid ? job.xid : null;
    },

  },

  mounted() {
  },

};
</script>

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-expand-transition', [_vm.EDUCATION ? _c('div', [_c('v-alert', {
    staticClass: "primary white--text",
    attrs: {
      "icon": "mdi-school"
    }
  }, [_vm._t("default")], 2)], 1) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }